<div #sidenav class="sidenav" [@slideInOut]="menuState$ | async">
  <div class="button-wrapper">
    <button type="button" class="close-button" (click)="toggleMenu()">
      <mat-icon svgIcon="close" />
    </button>
  </div>
  <ul class="sidenav__list">
    @for (button of menuItems; track $index) { @if (button.showOnMobile) {
    <li class="sidenav__item" (click)="navButtonAction(button)">
      {{ button.name }}
    </li>
    } }
  </ul>
</div>
