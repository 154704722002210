import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  icons = [
    'telegram',
    'facebook',
    'youtube',
    'instagram',
    'vk',
    'pir_logo_white',
    'close',
    'arrow',
    'trophy',
    'zen',
    'home',
    'burger-menu',
  ]; // названия svg иконок

  path = 'assets/images/icons';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platform: object,
  ) {}

  private registerIcon(iconName: string): void {
    if (isPlatformServer(this.platform)) {
      /* Register empty icons for server-side-rendering to prevent errors */
      this.matIconRegistry.addSvgIconLiteral(
        iconName,
        this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>'),
      );
    } else {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}/${iconName}.svg`,
        ),
      );
    }
  }

  public initIcons(): void {
    // TODO: добавить остальные иконки в регистр
    for (const iconName of this.icons) {
      this.registerIcon(iconName);
    }
  }
}
