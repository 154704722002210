import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { menuItems } from '@configs';
import { MenuItem } from '@models';
import { MenuService } from '@services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon],
  selector: 'app-footer',
  standalone: true,
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  menuItems = menuItems;

  readonly currentYear = new Date().getFullYear();

  constructor(private menuService: MenuService) {}

  navButtonAction(button: MenuItem): void {
    this.menuService.navButtonAction(button);
  }
}
