import { MenuItem } from '@models';

export const menuItems: MenuItem[] = [
  {
    disabled: false,
    isLink: true,
    name: 'Стать партнером',
    scrollToElement: null,
    showInFooter: false,
    showOnMobile: false,
    url: 'https://b24-150f9z.bitrix24.site/crm_form_4y9ao/',
  },
  // {
  //   name: 'Программа',
  //   url: 'program',
  //   scrollToElement: null,
  //   showOnMobile: true,
  //   showInFooter: true,
  //   disabled: false,
  // },
  // {
  //   disabled: false,
  //   name: 'Эксперты 2024',
  //   scrollToElement: 'experts',
  //   showInFooter: true,
  //   showOnMobile: true,
  // },
  // {
  //   disabled: false,
  //   name: 'Экспозиция 2025',
  //   scrollToElement: 'exhibitors',
  //   showInFooter: true,
  //   showOnMobile: true,
  // },
  {
    disabled: false,
    name: 'Партнеры 2024',
    scrollToElement: 'partners',
    showInFooter: true,
    showOnMobile: true,
  },
  {
    disabled: false,
    name: 'Место проведения',
    scrollToElement: 'location',
    showInFooter: true,
    showOnMobile: true,
  },
  {
    name: 'Новости',
    url: 'news',
    scrollToElement: null,
    showInFooter: true,
    showOnMobile: true,
    disabled: false,
  },
  {
    disabled: false,
    name: 'Как это было',
    scrollToElement: null,
    showInFooter: true,
    showOnMobile: true,
    url: 'gallery',
  },
  {
    disabled: false,
    isLink: true,
    name: 'ПРЕМИЯ «ШЕФ-ПОВАР ГОДА»',
    scrollToElement: null,
    showInFooter: false,
    showOnMobile: true,
    url: 'https://awards.bestchefs.ru/',
  },
];
