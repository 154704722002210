<div class="b-footer">
  <div class="b-footer__container b-container">
    <div class="b-footer__up-block">
      <a href="https://pirexpo.com/" target="_blank" class="b-footer__logo">
        <mat-icon svgIcon="pir_logo_white" />
      </a>
      <a href="tel:+74956379440" class="b-footer__telephone"
        >+7 (495) 637-94-40</a
      >
    </div>
    <ul class="b-footer__navbar">
      @for (button of menuItems; track $index) { @if (button.showInFooter) {
      <li class="b-footer__navbar-item" (click)="navButtonAction(button)">
        {{ button.name }}
      </li>
      } }
    </ul>
    <div class="b-footer__contacts">
      <div class="b-footer__title">контакты</div>
      <div class="b-footer__contact-columns">
        <div class="b-footer__contact-column">
          <div class="b-footer__contact-column-wrapper">

            <div class="b-footer__contact-item">
              <p>Вопросы по покупке билета:</p>
              <a href="mailto:e.gelya@pir.ru">e.gelya&#64;pir.ru</a>
            </div>
            <div class="b-footer__contact-item b-footer__contact-item--flex">
              <p>Вопросы по закрывающим документам и&nbsp;командировочным:</p>
              <a href="mailto:e.vilenskaya@pir.ru">e.vilenskaya&#64;pir.ru</a>
            </div>
            <div class="b-footer__contact-item b-footer__contact-item--flex">
              <p>Участие в программе мероприятий / спикер:</p>
              <a href="mailto:v.nekrasova@bestchefs.ru"
                >v.nekrasova&#64;bestchefs.ru</a
              >
            </div>
          </div>
        </div>
        <div class="b-footer__contact-column">
          <div class="b-footer__contact-item b-footer__contact-item--margin">
            Вопросы по партнерству и стендам:
          </div>
          <div
            class="b-footer__contact-item b-footer__contact-item--flex b-footer__contact-item--margin"
          >
            <p>Производители продуктов питания</p>
            <a href="mailto:a.tell@pir.ru">a.tell&#64;bestchefs.ru</a>
          </div>
          <div
            class="b-footer__contact-item b-footer__contact-item--flex b-footer__contact-item--margin"
          >
            <p>Проф. оборудование и инвентарь</p>
            <a href="mailto:sale@bestchefs.ru">sale&#64;bestchefs.ru</a>
            <p>
              <a href="mailto:a.chukhnakov@pir.ru"
                >a.chukhnakov&#64;pir.ru</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="b-footer__bottom-block">
      <div class="b-footer__text">
        &copy; Ярмарка ПИР , 1997-{{currentYear}}. Все права защищены.
      </div>
      <div class="b-footer__social-block">
        <a
          class="b-footer__icon b-footer__icon--telegram"
          href="https://t.me/chefsbreakfast"
          target="_blank"
        >
          <mat-icon svgIcon="telegram" />
        </a>
        <a
          class="b-footer__icon b-footer__icon--zen"
          href="https://zen.yandex.ru/chefsbreakfast"
          target="_blank"
        >
          <mat-icon svgIcon="zen" />
        </a>
        <a
          class="b-footer__icon b-footer__icon--vk"
          href="https://vk.com/chefsbreakfast"
          target="_blank"
        >
          <mat-icon svgIcon="vk" />
        </a>
        <a
          class="b-footer__icon b-footer__icon--youtube"
          href="https://www.youtube.com/channel/UCeigkyTy_SvCnVLh1LKnLrQ"
          target="_blank"
        >
          <mat-icon svgIcon="youtube" />
        </a>
      </div>
    </div>
  </div>
</div>
