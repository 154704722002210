<header class="header" [class.header_alt]="hideHeaderElement$$()">
  <div class="header__container">
    <nav class="header__nav">
      <a routerLink="/" class="header__home-link">
        <mat-icon class="header__home-icon" svgIcon="home" />
      </a>
      <ul class="header__nav-list">
        @for (button of menuItems; track $index) {
        <li class="header__nav-item">
          <a class="header__nav-link" (click)="navButtonAction(button)">
            {{ button.name }}
          </a>
        </li>
        }
      </ul>
    </nav>
    <button
      class="header__buy-button"
      id="pirexpo-ticket-widget"
      data-pirexpo-widget-shop-id="1"
      type="button"
    >
      купить билет
    </button>

    <button class="header__menu" (click)="toggleMenu()">
      <mat-icon svgIcon="burger-menu" />
    </button>
  </div>
</header>
