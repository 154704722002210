import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { menuItems } from '@configs';
import { MenuItem } from '@models';
import { WINDOW } from '@ng-web-apis/common';
import { MenuService, ToggleSidenavService } from '@services';
import { filter, map, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  standalone: true,
  imports: [MatIcon, RouterLinkWithHref],
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  activeMenu!: boolean;
  menuItems = menuItems;

  private readonly urls = ['/news', '/gallery'];

  hideHeaderElement$$ = toSignal(
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => this.urls.includes(event.url)),
    ),
  );

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    private sidenav: ToggleSidenavService,
    private menuService: MenuService,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
  ) {}

  navButtonAction(button: MenuItem): void {
    this.menuService.navButtonAction(button);
  }

  @HostListener('window:scroll')
  onScroll(): void {
    const scrollY = this.windowRef.scrollY;
    const element = this.element.nativeElement.children[0];
    const elementHeight = element.offsetHeight;

    scrollY > elementHeight
      ? this.renderer.addClass(element, 'header_scrolled')
      : this.renderer.removeClass(element, 'header_scrolled');
  }

  toggleMenu(): void {
    this.sidenav.toggleSidebar.emit();
  }
}
